import React from 'react';
import Lane from '../lib/Lane';
import Request from '../lib/Request';
import Endpoints from '../lib/Endpoints';
import Display from '../lib/Display';

export default class ModalKeypad extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locationId: "",
            lane: "",
            code: "",
            offlineCode: "999"
        }
    }

    clearCode = () => {
        this.setState({code: ""});
    }

    validateEmployee() {
        return (this.props.app.employeeCodes.indexOf(this.state.code) >= 0 || this.state.code === this.state.offlineCode);
    }

    async completeTraining() {
        const validatePromise = await Request.put(Endpoints.completeTraining(this.props.app.reservationNumber), {});
        return validatePromise;
    }

    async startReservation() {
        const validatePromise = await Request.put(Endpoints.startReservation(this.props.app.reservationNumber), {});
        return validatePromise;
    }

    decideNextStep = () => {
        if (this.props.app.accessingAdmin) {
            this.closeKeypad();
            Display.toggleAdminModal(true);
        } else if (this.props.app.reservationTimeout && !this.props.app.reservationExtended) {
            if (this.props.app.adminOverrideTimeout) {
                Lane.extendReservation();
                this.closeKeypad();
            } else {
                // TODO: Make api request to complete reservation
                window.location.reload(false);
            }
        } else if (!this.props.app.groupInLane) {
            Lane.getHitMessages();
            Lane.toggleGroupInLane(true);
            this.closeKeypad();
            Display.toggleBrowseGames(false);
            Display.toggleAdminChooseActions(true);
        } else if (this.props.app.startedTraining && !this.props.app.completedTraining) {
            this.completeTraining().then(response => {
                if (response.status === 200 || response.status === 304) {
                    Lane.toggleCompletedTraining(true);
                    this.closeKeypad();
                } else {
                    alert('There was a problem completing the training');
                }
            });
        } else if (!this.props.app.completedTraining) {
            alert('Participants must complete training before playing');
        } else if (this.props.app.completedTraining) { // &&!currentGame
            this.startReservation().then(response => {
                if (response.status === 200 || response.status === 304) {
                    Lane.toggleCompletedTraining(true);
                    Lane.startReservation();
                    Lane.startReservationTime(this.props.app.reservationDuration);
                    this.closeKeypad();
                    Display.toggleAdminChooseActions(false);
                    Display.toggleBrowseGames(true);
                } else {
                    alert('There was a problem starting the reservation');
                }
            });
        }
    }

    evaluateAction = () => {
        if (this.validateEmployee()) {
            this.decideNextStep();
        } else {
            alert('The code you entered is invalid');
            this.clearCode();
        }
    }

    closeKeypad = () => {
        Lane.toggleAccessingAdmin(false);
        Display.toggleModalKeypad(false);
        this.clearCode();
    }

    keyPressed = (key) => {
        this.setState({code: this.state.code + key});
    }

    render() {
        return (
            <div className={`modal modal-keypad underlay ${this.props.display.showModalKeypad ? "active" : ""} ${this.props.app.reservationTimeout && !this.props.app.reservationExtended ? "on-top" : ""}`}>
                <div className="modal-body">
                    <div className="input-group">
                        <label>Enter Employee Code</label>
                        <input type="password" value={this.state.code} disabled/>
                    </div>
                    <div className="keypad">
                        <div className="btn btn-default" onClick={() => this.keyPressed(1)}>1</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed(2)}>2</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed(3)}>3</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed(4)}>4</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed(5)}>5</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed(6)}>6</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed(7)}>7</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed(8)}>8</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed(9)}>9</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed('*')}>*</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed(0)}>0</div>
                        <div className="btn btn-default" onClick={() => this.keyPressed('#')}>#</div>
                    </div>
                    <div className="btn btn-gold btn-block btn-enter" onClick={this.evaluateAction}>Enter</div>
                    <div className="btn btn-blank btn-block btn-close" onClick={this.closeKeypad}>Cancel</div>
                </div>
            </div>
        )
    }
}