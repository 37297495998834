import Request from './Request';
import Endpoints from './Endpoints';
import Dispatcher from '../dispatchers/Dispatcher';
import Actions from './Actions';

/**
 * Helper class around reservations
 */
export default class Reservation {

    static processImages = (response) => {
        if (response && response.data && response.data.data) {
            if (response.data.data.appLogo != null) {
                Dispatcher.dispatch({
                    type: Actions.SET_APP_LOGO,
                    data: response.data.data.appLogo
                });
            }  else {
                Dispatcher.dispatch({
                    type: Actions.RESET_APP_LOGO,
                    data: response.data.data.appLogo
                });
            }

            if (response.data.data.targetImage != null) {
                Dispatcher.dispatch({
                    type: Actions.SET_TARGET_IMAGE,
                    data: response.data.data.targetImage
                });
            } else {
                Dispatcher.dispatch({
                    type: Actions.RESET_TARGET_IMAGE,
                    data: response.data.data.targetImage
                });
            }

            if (response.data.data.adVideo != null) {
                Dispatcher.dispatch({
                    type: Actions.SET_AD_VIDEO,
                    data: response.data.data.adVideo
                });
            } else {
                Dispatcher.dispatch({
                    type: Actions.RESET_AD_VIDEO,
                    data: response.data.data.adVideo
                });
            }

            Dispatcher.dispatch({
                type: Actions.SET_INITIAL_FETCH,
                data: true
            });
        }
    }

    /**
     * Load a reservation and dispatch its data with LOAD_RESERVATION
     */
    static load = (locationId, laneNumber, callback) => {
        Request.get(Endpoints.getReservation(locationId, laneNumber)).then((response) => {
            Dispatcher.dispatch({
                type: Actions.LOAD_RESERVATION,
                data: response.data.data
            });

            this.processImages(response);

            if (callback) {
                callback();
            }
        }).catch(err => {
            this.processImages(err.response);
        });
    }

    static processGameData = (gameData) => {
        // if (typeof gameData !== 'undefined' && typeof gameData.data !== 'undefined' && typeof gameData.data.data !== 'undefined' && typeof gameData.data.data.jsonBlock !== 'undefined' && typeof gameData.data.data.jsonBlock.jsonBlock !== 'undefined') {
        // if (typeof gameData != null && typeof gameData.data != null && typeof gameData.data.data != null && typeof gameData.data.data.jsonBlock != null && typeof gameData.data.data.jsonBlock.jsonBlock != null) {
        //     return gameData.data.data.jsonBlock.jsonBlock;
        // } else {
        //     return false;
        // }
        try {
            return gameData.data.data.jsonBlock.jsonBlock.gameState;
        } catch(err) {
            return false;
        }
    }

    static processData = (gameData) => {
        // if (typeof gameData !== 'undefined' && typeof gameData.data !== 'undefined' && typeof gameData.data.data !== 'undefined' && typeof gameData.data.data.jsonBlock !== 'undefined' && typeof gameData.data.data.jsonBlock.jsonBlock !== 'undefined') {
        // if (typeof gameData != null && typeof gameData.data != null && typeof gameData.data.data != null && typeof gameData.data.data.jsonBlock != null && typeof gameData.data.data.jsonBlock.jsonBlock != null) {
        //     return gameData.data.data.jsonBlock.jsonBlock;
        // } else {
        //     return false;
        // }
        try {
            return gameData.data.data.jsonBlock.jsonBlock;
          } catch(err) {
            return false;
        }
    }
}